import store from '@/store';
import router from '@/router';

import globalHelper from '@/helpers/global.helper';

export const getError = (errRes) => {
  const errorData = errRes.data.error;
  if (typeof errorData === 'object') {
    const errValArray = Object.values(errorData);
    return errValArray.join('\n');
  } else if (Array.isArray(errorData) && errorData.length) {
    return errorData.join('\n');
  }
  return errorData || 'Server Error!';
};

export const toastErr = ((error, errMsg = null) => 
  store.commit('snackbar/SHOW_MESSAGE', {
    text: error && typeof error != 'undefined' ? getError(error) : errMsg,
    color: "error",
  })
)

export const ErrorValidation = (error) => {
  if (error && error.response && error.response.status && error.response.status < 500) {
    return errStatusUpdate(error.response);
  }
  return toastErr(error && error.response ? error.response : null, 'Server Error!');
};

export const errStatusUpdate = (errorResp) => {
  switch (errorResp.status) {
    case 401:
      globalHelper.logout();
      toastErr(null, 'Token expired');
    default:
      return toastErr(errorResp, 'Server Error!');
  }
}
