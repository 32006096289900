import Vue from 'vue';
import Vuex from 'vuex';

import themeConfig from '@/store/themeConfig';
import snackbar from '@/store/common-modules/snackbar';
import dashboard from '@/store/modules/dashboard';
import MemberTheme from '@/store/modules/member-theme';
// Load Vuex
Vue.use(Vuex);

// Create store
export default new Vuex.Store({
  modules: {
    themeConfig,
    snackbar,
    dashboard,
    MemberTheme
  },
})
