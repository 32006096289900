import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

const router = new Router({
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  mode: "history",
  routes: [
    {
      path: "/",
      component: () => import("@/pages/index"),
      props: route => ({ query: route.fullPath.indexOf('?slug') !== -1 ? route.query.slug : null}),
      children: [
        {
          path: "/", name: 'Dashboard',
          component: () => import("@/pages/dashboard/index")
        }
      ]
    }, {
        path: "/3d-secure-completed", name: 'Success',
        component: () => import("@/pages/dashboard/component/3DSecureModal")
    }, {
      path: "**",
      component: () => import("@/pages/error/index"),
    },
  ],
});

export default router;
