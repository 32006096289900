// theme Main scss
import "@/assets/scss/octavia-design-system/octavia-design-system.scss";

// plugins menu
// axios
import axios from "axios";
import VueAxios from "vue-axios";

import VueMeta from "vue-meta";

//validation
import Vuelidate from 'vuelidate';

// end plugins

export default {
  install(Vue) {
    Vue.use(VueMeta);
    Vue.use(VueAxios, axios);
    Vue.use(Vuelidate);
    Vue.component(
      "VerticalSix",
      // The `import` function returns a Promise.

      () => import("@/layouts/component/Index")
    );
  },
};
