import Vue from 'vue';

//NPM Style Sheets
import '@/assets/css/address.css';
import '@/assets/js/edq-validate-ui.min.js';
import 'jarallax/dist/jarallax.js';
import 'jarallax/dist/jarallax.css';

//Component Imports
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import globalHelper from '@/helpers/global.helper';

//Theme plugin Imports
import vuetify from '@/plugins/vuetify';
import '@/plugins';
import themeKit from '@/plugins/theme.kit';

Vue.use(themeKit);
Vue.config.productionTip = false;

Vue.use({
  install() {
    Vue.helpers = globalHelper
    Vue.prototype.$helpers = globalHelper
  }
});

new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
