<template>
  <v-app>
    <router-view/>
    <Snackbar></Snackbar>
  </v-app>
</template>

<script>

export default {
  name: "App",
  components: {
    Snackbar: () => import("@/components/common/Snackbar"),
  },
};
</script>