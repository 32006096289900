import colors from "vuetify/lib/util/colors";
export const themeConfig = {
  rtl: false, // options[Boolean] : true, false(default)
  verticalSidebarMini: false, // options[Boolean] : true, false(default)
  verticalSidebarDrawer: false, // options[Boolean] : true, false(default)
  verticalCompactSidebarDrawer: true, // options[Boolean] : true, false(default)
  verticalSaasSidebarDrawer: true, // options[Boolean] : true, false(default)
  showBreadcrumb: true, // options[Boolean] : true, false(default)

  layout: "VerticalSix",
  isLoading: false,
  isDark: false,
  verticalSidebarDrawerColor: "white", // primary,white,sucsess,warning,etc
  appBarColor: "white", // primary,white,sucsess,warning,etc
};

export const themePreset = {
  breakpoint: {
    scrollBarWidth: 16,
    thresholds: {
      xs: 600,
      sm: 960,
      md: 1280,
      lg: 1920,
    },
  },

  rtl: false,

  theme: {
    dark: false,

    default: "light",
    disable: false,
    options: {
      cspNonce: undefined,
      customProperties: true,
      minifyTheme: undefined,
      themeCache: undefined,
    },
    themes: {
      light: {
        primary: "#0032A0",
        secondary: "#E1248E",
        success: "#6BBC3E",
        danger: "#FF0000",
        error: "#FF0000",
        warning: '#FF8401',
        info: "#0486DE",
        dark: "#242939",
        accent: "#82B1FF",
        background: "#EFEFF0",
        donotread: '#FF5252',
        iconbg: '#1976D2',
        paragraph: '#434445',
        anchor: '#0032A0'
      },
      dark: {
        primary: colors.blue,
        secondary: colors.pink,
        success: colors.green,
        danger: colors.red,
        warning: colors.deepOrange,
        info: colors.indigo,
        accent: colors.accent,
      },
    },
  },
};
